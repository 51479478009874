import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import Script from 'react-load-script';
import { Icon, Message, Progress, Button } from 'semantic-ui-react';

import styles from './Scene.module.scss';

import { connect } from 'react-redux';

import { getWebglReport } from '../../../helpers/getWebglReport';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Copyright from '../../1_atoms/Copyright/Copyright';
import sleep from '../../../helpers/sleep.js';

const ifvisible = require('ifvisible.js');

const key = 'NRZTA-V9EJF-FVTMW-55D6F';

class Scene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: null,
      showAvatar: false,
      vm: false,
      pause: false,
      mode: 'loading', // 'loading', 'wait','delay ,'running'
    };

    ifvisible.on('blur', () => {
      if (this._vuppetmaster) {
        this._vuppetmaster.core.stopAnimation();
      }
    });

    ifvisible.on('focus', () => {});

    this._model = null;
    this._head = null;
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleScriptCreate() {}

  handleScriptError() {
    this.setState({
      scriptError: true,
    });
  }

  async handleScriptLoad() {
    this._vuppetmaster = new window.VM.Vuppetmaster({
      assetsQuality: 'optimized',
    });
    document._vm = this._vuppetmaster;
    this._vuppetmaster.on('complete', async () => {
      // ready
      this._model = this._vuppetmaster.models.getFirst();
      this._head = this._model.createCtrlNode('head');

      await this._vuppetmaster.core.preloadAll((value) => {
        this.setState({ progress: (value / 2 + 0.5) * 100 });
      });

      this.setState({ mode: 'wait' });

      document.addEventListener(
        'mousemove',
        (event) => {
          const x = (event.clientX / document.body.clientWidth - 0.5) * 1;
          const y = (event.clientY / document.body.clientHeight - 0.5) * 1;
          //          console.log('##', x, y);
          this._head.setEuler((x * Math.PI) / 8, (y * Math.PI) / 8, 0);
        },
        false
      );
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.touches.length == 1) {
            const x =
              (event.touches[0].clientX / document.body.clientWidth - 0.5) * 1;
            const y =
              (event.touches[0].clientY / document.body.clientHeight - 0.5) * 1;
            this._head.setEuler((x * Math.PI) / 8, (y * Math.PI) / 8, 0);
          }
        },
        false
      );
      this.setState({ progress: null, vm: true });
    });
    this._vuppetmaster.on('project.firstsequence', (data) => {
      setTimeout(() => {
        this.setState({ mode: 'running' });
      }, 0);
    });
    this._vuppetmaster.on('project.progress', (result) => {
      this.setState({ progress: (result.value * 100) / 2 });
    });

    this._vuppetmaster.start({
      app: 'gebaerdensprach-avatar',
      start: false,
    });
  }

  onStart() {
    this.setState({ mode: 'delay' });
    this._vuppetmaster.core.playProject();
  }

  onPause() {
    const pause = !this.state.pause;
    this.setState({ pause });
    if (pause) {
      this._vuppetmaster.trainer.pause();
    } else {
      this._vuppetmaster.trainer.resume();
    }
  }

  render() {
    const { progress, pause, vm, mode } = this.state;
    const { login } = this.props;

    const backgroundStyle = {
      backgroundImage:
        'url(' + process.env.PUBLIC_URL + '/assets/wire-head.jpg)',
    };

    let vmurl =
      login &&
      (process.env.NODE_ENV === 'production'
        ? 'https://vuppetmaster.de/vmapi/v4/api?key=' + key
        : 'http://localhost:9443/vmapi/v4.local/api?key=' + key);

    return (
      <div className={styles.main} style={backgroundStyle}>
        {vmurl && (
          <Script
            url={vmurl}
            onCreate={this.handleScriptCreate.bind(this)}
            onError={this.handleScriptError.bind(this)}
            onLoad={this.handleScriptLoad.bind(this)}
          />
        )}
        {progress && (
          <Progress
            style={{
              position: 'absolute',
              width: 'calc(100% - 2em)',
              zIndex: '500',
            }}
            percent={progress}
            color={'orange'}
            size="tiny"
            active
          ></Progress>
        )}
        {(mode === 'loading' || mode === 'wait') && (
          <div>
            <img
              className={styles.logo}
              src={process.env.PUBLIC_URL + '/assets/logokommunal.png'}
              alt="vm"
            />
          </div>
        )}
        <div className={styles.render}>
          {(mode === 'wait' || mode === 'delay' || mode === 'loading') && (
            <div className={styles.wait}>
              {mode === 'loading' && (
                <div className={styles.center}>
                  {progress && progress > 0 && progress < 100 && (
                    <div>{`Loading ... ${Math.floor(progress)}%`}</div>
                  )}
                  {!progress && <div>{`Loading`}</div>}
                  {progress && progress === 100 && <div>Initializing</div>}
                </div>
              )}
              {mode === 'wait' && (
                <Button
                  size="huge"
                  color="orange"
                  className={styles.center}
                  onClick={() => {
                    this.onStart();
                  }}
                >
                  Start
                </Button>
              )}
            </div>
          )}

          <div id="webglcontainer" className={styles.renderview}></div>
        </div>
        {pause && <div className={styles.overlayPause}></div>}
        {pause && (
          <div className={styles.overlayPauseButton}>
            <Button
              className={styles.center}
              onClick={() => {
                this.onPause();
              }}
              circular
              size={'huge'}
              icon
            >
              <Icon size={'big'} name="play" />
            </Button>
          </div>
        )}
        {mode === 'running' && !pause && (
          <div className={styles.pause}>
            <Button
              color={'orange'}
              size="tiny"
              icon
              onClick={() => {
                this.onPause();
              }}
            >
              <Icon name="pause" />
            </Button>
          </div>
        )}
        <Copyright />
      </div>
    );
  }
}

Scene = connect(
  (state) => ({
    login: state.login.login,
  }),
  {} // bind account loading action creator
)(Scene);

export default Scene;
